import { ComponentType } from '@angular/cdk/portal';
import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { RecurringInterval } from '@ih/enums';
import { SubscriptionPrice } from '@ih/interfaces';
import { LazyDialogService } from '@ih/lazy-dialog';
import { LazySnackBarService, SubscriptionService } from '@ih/services';
import { getIntervalText } from '@ih/utilities';
import { Observable, catchError, from, map, switchMap, throwError } from 'rxjs';
import { type ChoosePlanDialogComponent } from './choose-plan-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class ChoosePlanDialogService {
  private lazyDialog = inject(LazyDialogService);
  private http = inject(HttpClient);
  private snackbar = inject(LazySnackBarService);
  private subscription = inject(SubscriptionService);

  getPricingTable(channelId: number | null): Observable<SubscriptionPrice[]> {
    return this.http
      .get<SubscriptionPrice[]>(`/api/campaign/pricingTable`, {
        params: { channelId: channelId ? channelId.toString() : '' }
      })
      .pipe(
        catchError((err) => {
          this.snackbar.open('Failed to load pricing table');

          throw err;
        }),
        map((pricingTable) =>
          pricingTable.map((p) => ({
            ...p,
            interval: p.interval || RecurringInterval.Never,
            intervalText: getIntervalText(p.interval, p.intervalCount)
          }))
        )
      );
  }

  showPricingTable(channelId: number | null): Observable<void> {
    return this.getPricingTable(channelId).pipe(
      switchMap((pricingTable) => {
        // if there are no prices then we don't need to show the dialog
        if (pricingTable.length === 0) {
          this.snackbar.open('There is currently no price set for this channel. Please try again later.');
          return throwError(() => new Error('NO_PRICES'));
        }

        return from(this.open(pricingTable)).pipe(switchMap((dialog) => dialog.afterClosed()));
      }),
      switchMap((price) => {
        if (price) {
          const url = new URL(window.location.href);
          // return url is origin + path without query params or hash
          const returnUrl = `${url.origin}/c/${channelId}`;
          this.subscription.showStripeCheckout(price, returnUrl);
        }

        return throwError(() => new Error('CANNOT_JOIN_PAID_CHANNEL'));
      })
    );
  }

  async open(prices: SubscriptionPrice[]): Promise<MatDialogRef<ChoosePlanDialogComponent, SubscriptionPrice>> {
    const dialog = await this.lazyDialog.getDialogService();

    const choosePlanDialogComponent: ComponentType<ChoosePlanDialogComponent> = await import(
      './choose-plan-dialog.component'
    ).then((m) => m.ChoosePlanDialogComponent);

    return dialog.open<ChoosePlanDialogComponent, SubscriptionPrice[], SubscriptionPrice>(choosePlanDialogComponent, {
      id: 'choosePlanDialog',
      data: prices,
      closeOnNavigation: true,
      panelClass: ['choose-plan-dialog', 'basic-dialog', 'fullscreen-lt-sm'],
      maxWidth: undefined,
      minHeight: undefined
    });
  }
}
